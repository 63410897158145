export const AdminRole = {
    Admin: 'QlubAdmin',
    Analyst: 'QlubAnalyst',
    CountryAnalyst: 'QlubCountryAnalyst',
    Monitor: 'QlubMonitor',
    CountryMonitor: 'QlubCountryMonitor',
    SuperAdmin: 'QlubSuperAdmin',
    Finance: 'QlubFinance',
};

export const VendorRole = {
    VendorAdmin: 'VendorAdmin',
    BrandAdmin: 'BrandAdmin',
    BrandAnalyst: 'BrandAnalyst',
    VendorAnalyst: 'VendorAnalyst',
    VendorFinance: 'VendorFinance',
};

export const qlubAdminRoles = [
    AdminRole.Admin,
    AdminRole.Analyst,
    AdminRole.CountryAnalyst,
    AdminRole.Monitor,
    AdminRole.CountryMonitor,
    AdminRole.SuperAdmin,
    AdminRole.Finance,
];
const vendorRoles = [
    VendorRole.VendorAdmin,
    VendorRole.BrandAdmin,
    VendorRole.BrandAnalyst,
    VendorRole.VendorAnalyst,
    VendorRole.VendorFinance,
];

export const BrandScheduledReportRole = [VendorRole.BrandAdmin, VendorRole.BrandAnalyst];

export const restrictRoles = {
    HOME: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    REPORTS: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    TRANSACTIONS: [VendorRole.VendorAdmin, VendorRole.VendorAnalyst, VendorRole.VendorFinance, ...qlubAdminRoles],
    PAYMENTS: [VendorRole.VendorAdmin, VendorRole.VendorAnalyst, VendorRole.VendorFinance, ...qlubAdminRoles],
    ORDERS_AND_TRANSACTIONS: [
        VendorRole.VendorAdmin,
        VendorRole.VendorAnalyst,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    REVIEWS: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    NOTIFICATION: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    RESTAURANT_DETAILS: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    RESTAURANT_DETAILS_EDIT: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        AdminRole.Monitor,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    MENUS: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    QR_CODE: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    FOODICS: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    LS_K_SERIES: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    LS_O_SERIES: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    VPOS: [
        VendorRole.VendorAdmin,
        VendorRole.VendorAnalyst,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    PROFILE_SETTINGS: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    TABLE_VIEW: [VendorRole.VendorAdmin, VendorRole.VendorAnalyst, VendorRole.VendorFinance, ...qlubAdminRoles],
    EXPORT_ORDERS_AND_TRANSACTIONS: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    EXPORT_BRAND_REPORTS: [VendorRole.BrandAdmin, VendorRole.BrandAnalyst],
    BRAND_DASHBOARD: [VendorRole.BrandAdmin, VendorRole.BrandAnalyst],
    SCHEDULED_REPORTS: [VendorRole.VendorAdmin, VendorRole.BrandAdmin, VendorRole.BrandAnalyst, ...qlubAdminRoles],
    REFUND: [VendorRole.BrandAdmin, VendorRole.BrandAnalyst, VendorRole.VendorFinance],
    PAYMENT_LINK: [VendorRole.VendorAdmin, VendorRole.VendorAnalyst, VendorRole.VendorFinance, ...qlubAdminRoles],
    BRAND_VERIFICATION_BANNER: [VendorRole.BrandAdmin, VendorRole.VendorAdmin, ...qlubAdminRoles],
    LINKIT: [
        VendorRole.VendorAdmin,
        VendorRole.BrandAdmin,
        VendorRole.BrandAnalyst,
        VendorRole.VendorFinance,
        ...qlubAdminRoles,
    ],
    LOYALTY: [AdminRole.Admin, AdminRole.SuperAdmin],
};
