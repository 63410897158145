import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useLanguageQuery, useTranslation } from 'next-export-i18n';
import DataTable from 'react-data-table-component';
import { cloneDeep, isEqual } from 'lodash';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import EventBus from '@/config/event-handler';
import { useRestaurantContext } from '@/contexts/restaurant';
import { vendorOrderEvent } from '@/contexts/event.const';
import { useUserContext } from '@/contexts/user';
import QsrOrdersService from '@/services/qsrOrders';
import { timeUnix } from '@/services/utils/k_time';
import RestaurantService from '@/services/restaurant';
import { covertUtcToRestaurantDateTime, getCurrencyWithAmount } from '@/common/utility';
import { IOrderNotificationEvent } from '@/views/Payments';

import QsrGridView from '@/components/QsrOrders/qsrGridView';
import type { ITimeRangeForm } from '@/components/TimeRange/Form/types';
import { IRestaurant, ITableParams, OrderPriceRoundingModeEnum } from '@/views/OrdersTableView/types';
import { IOrderItemStatus } from '@/components/QsrOrderAction';
import { parsePrinterConfig, transformPrinterConfig } from '@/components/PosPrinteCenter/QsrOrderSettings';
import useLocalStorage from '@/hooks/useLocalStorage';
import { PageTitle, Section } from '@/components/common';
import { timeRangeTitles } from '@/components/TimeRange/Form/hooks/useData';
import QsrOrdersDetails from '@/components/QsrOrderDetails';
import KKTablePagination from '@/components/Table/pagination';
import QSREditOrder, { IQSREditOrderFunctions, QSREditOrderType } from '@/components/QSREditOrder';

import { useSnackbar } from 'notistack';
import { useColumns } from './useColumns';
import { IQsrOrder, IQsrOrderNotification, OrderStatusEnum } from './types';
import { getIcon, useFilters, useQueryParams } from './useFilters';

export function convertOrderToTableRow(
    orderItem: IQsrOrder,
    lang: string,
    currencyCode: string,
    currencySymbol: string,
    roundingMode?: OrderPriceRoundingModeEnum,
): IQsrOrderNotification {
    const date =
        lang !== 'ja'
            ? covertUtcToRestaurantDateTime(orderItem.createdAt, 'MM/DD/YYYY HH:mm a')
            : covertUtcToRestaurantDateTime(orderItem.createdAt, 'YYYY/MM/DD HH:mm');

    return {
        ...orderItem,
        totalStr: getCurrencyWithAmount(orderItem.total, currencyCode, currencySymbol, true, roundingMode),
        subTotalStr: orderItem.subTotal
            ? getCurrencyWithAmount(orderItem.subTotal, currencyCode, currencySymbol, true, roundingMode)
            : undefined,
        date,
    };
}

const transformOrders = (
    orders: IQsrOrderNotification[],
    qrMap: {
        [key: string]: string;
    },
    defaultStatus?: OrderStatusEnum,
) => {
    return orders.map((o) => ({
        ...o,
        orderStatus: o.orderStatus || { name: defaultStatus || OrderStatusEnum.Pending },
        ...(o.urlData
            ? {
                  urlData: {
                      ...o.urlData,
                      name:
                          o.urlData.name ||
                          qrMap[`${o.urlData.tableId || '_'}/${o.urlData.f1 || '_'}/${o.urlData.f2 || '_'}`],
                  },
              }
            : {}),
    }));
};

const getQrNameMap = (list: ITableParams[]) => {
    return list.reduce<{ [key: string]: string }>((acc, curr) => {
        acc[`${curr.id}/${curr.f1 || '_'}/${curr.f2 || '_'}`] = curr.name || curr.id;
        return acc;
    }, {});
};

const trimOrders = (
    orders: IQsrOrderNotification[],
    statuses: string[],
    notInStatuses: string[],
    qrMap: { [key: string]: string },
    defaultStatus?: OrderStatusEnum,
) => {
    if (statuses.length === 0 && notInStatuses.length === 0) {
        return transformOrders(orders, qrMap, defaultStatus);
    }
    return transformOrders(
        orders.filter((o) => {
            return (
                (statuses.length === 0 || (statuses.length > 0 && statuses.includes(o.orderStatus?.name || ''))) &&
                (notInStatuses.length === 0 ||
                    (notInStatuses.length > 0 && !notInStatuses.includes(o.orderStatus?.name || '')))
            );
        }),
        qrMap,
        defaultStatus,
    );
};

export enum QSRCardModeEnum {
    Normal = 'normal',
    Pending = 'pending',
    NotPending = 'notPending',
    Failed = 'failed',
    Scheduled = 'scheduled',
}

interface IProps {
    mode: QSRCardModeEnum;
    restaurant: IRestaurant;
    view: string;
    timeRange: ITimeRangeForm;
    selectedStatus: string[];
    notInSelectedStatus?: string[];
    refId?: string;
    refreshTrigger?: number;
    hide?: boolean;
    qsrEditOrderRef: QSREditOrderType;
    onReloadOrder: (refId: string) => Promise<any>;
}

interface GetOrderOptions {
    isRefresh?: boolean;
    noLoading?: boolean;
}

function QsrOrdersInner({
    mode,
    restaurant,
    view,
    timeRange,
    selectedStatus,
    refId,
    refreshTrigger,
    notInSelectedStatus,
    hide,
    qsrEditOrderRef,
    onReloadOrder,
}: IProps) {
    const priceRoundingMode = restaurant?.order_config?.priceRoundingMode;
    const { t } = useTranslation('common');
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const qsrOrdersService = QsrOrdersService.getInstance();
    const restaurantService = RestaurantService.getInstance();
    const { user } = useUserContext();
    const [data, setData] = useState<IQsrOrderNotification[]>([]);
    const [loading, setLoading] = useState(true);
    const loadingRef = useRef(false);
    const [selectedRow, setSelectedRow] = useState<IQsrOrder | null>(null);
    const [qrNameMap, setQrNameMap] = useState<{ [key: string]: string }>({});
    const columns = useColumns({
        setSelectedRow,
        currencySymbol: restaurant?.restaurant_country?.currency_symbol || '',
        currencyCode: restaurant?.restaurant_country?.currency_code || '',
        priceRoundingMode,
    });
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [totalRow, setTotalRow] = useState<number>(0);
    const [more, setMore] = useState(true);
    const { isReady } = useRouter();
    const lastUpdateRef = useRef(0);
    const isFirstRender = useRef(true);

    const tablePageChangeHandler = (p: number) => {
        setPage(p);
    };

    const tableRowsPerPageChangeHandler = (val: number) => {
        setLimit(val);
    };

    const getQrs = (): Promise<{ [key: string]: string }> => {
        if (!restaurant || Object.keys(qrNameMap).length > 0) {
            return Promise.resolve(qrNameMap);
        }

        return restaurantService.getQrs(restaurant.id).then((res) => {
            const qrm = getQrNameMap(res.rows.map((o) => o.params) || []);
            setQrNameMap(qrm);
            return qrm;
        });
    };

    const getOrderHandler = (options: GetOrderOptions) => {
        const { isRefresh = false, noLoading = false } = options;
        if (!restaurant || loadingRef.current) {
            return;
        }

        loadingRef.current = true;
        if (!noLoading) {
            setLoading(true);
        }
        setData([]);

        Promise.all([
            qsrOrdersService.getQsrOrders({
                restaurantId: restaurant.id,
                page: refId ? 0 : page - 1,
                limit,
                refId,
                ...(selectedStatus.length > 0 ? { statuses: selectedStatus } : {}),
                ...(notInSelectedStatus?.length ? { notInStatuses: notInSelectedStatus } : {}),
                ...(timeRange && { startDate: timeRange.from, endDate: timeRange.to }),
            }),
            getQrs(),
        ])
            .then(([res, qrMap]) => {
                lastUpdateRef.current = timeUnix();
                const orderList =
                    res.orders
                        ?.map((item) =>
                            convertOrderToTableRow(
                                item,
                                lang,
                                restaurant?.restaurant_country?.currency_symbol || '',
                                restaurant?.restaurant_country?.currency_code || '',
                                restaurant?.order_config?.priceRoundingMode || OrderPriceRoundingModeEnum.Round,
                            ),
                        )
                        .sort((a: any, b: any) => {
                            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                        }) || [];

                setMore(res.orders.length >= limit);
                const defaultOrderStatus = restaurant?.order_config?.defaultOrderStatus;
                if (mode === QSRCardModeEnum.Pending && !refId && !isRefresh) {
                    setData((o) =>
                        trimOrders(
                            [...o, ...orderList],
                            selectedStatus || [],
                            notInSelectedStatus || [],
                            qrMap,
                            defaultOrderStatus,
                        ),
                    );
                } else {
                    setData(
                        trimOrders(
                            orderList,
                            selectedStatus || [],
                            notInSelectedStatus || [],
                            qrMap,
                            defaultOrderStatus,
                        ),
                    );
                }
                setTotalRow(res?.pagination?.count || 0);
            })
            .catch((err: any) => {
                console.log(err);
            })
            .finally(() => {
                if (!noLoading) {
                    setLoading(false);
                }
                loadingRef.current = false;
                isFirstRender.current = false;
            });
    };

    useEffect(() => {
        if (isReady && query?.view) {
            getOrderHandler({});
        }
    }, [restaurant.id, selectedStatus.toString(), timeRange, page, limit, refId, query]);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }
        getOrderHandler({
            isRefresh: true,
        });
    }, [refreshTrigger]);

    useEffect(() => {
        if (
            (!user || (user.userData.role || '').indexOf('Vendor') === -1 || page > 1) &&
            !restaurant?.order_config?.vendorEditOrderEnable
        ) {
            return () => {
                //
            };
        }

        const fetchDataHandler = ({ detail: msgList }: IOrderNotificationEvent) => {
            lastUpdateRef.current = timeUnix();
            setData((li) => {
                const tempLi = cloneDeep(li);
                msgList.forEach((msg) => {
                    const order = convertOrderToTableRow(
                        msg,
                        lang,
                        restaurant?.restaurant_country?.currency_symbol || '',
                        restaurant?.restaurant_country?.currency_code || '',
                        restaurant?.order_config?.priceRoundingMode || OrderPriceRoundingModeEnum.Round,
                    );
                    const idx = tempLi.findIndex((o) => o.id === order.id);
                    if (idx > -1) {
                        tempLi[idx] = { ...tempLi[idx], ...order };
                    } else {
                        tempLi.unshift(order);
                    }
                });
                return trimOrders(
                    tempLi,
                    selectedStatus || [],
                    notInSelectedStatus || [],
                    qrNameMap,
                    restaurant?.order_config?.defaultOrderStatus,
                );
            });
        };

        EventBus.on(vendorOrderEvent, fetchDataHandler);
        return () => {
            EventBus.remove(vendorOrderEvent, fetchDataHandler);
        };
    }, [user, page, restaurant, lang, qrNameMap]);

    useEffect(() => {
        if (!restaurant || Number(restaurant.order_config?.fetchOrderInterval || 0) <= 0) {
            return () => {
                //
            };
        }
        const intervalAmount = Math.max(restaurant?.order_config?.fetchOrderInterval || 0, 10);
        const interval = setInterval(() => {
            if (timeUnix() - lastUpdateRef.current < intervalAmount * 1.5) {
                return;
            }
            console.info('reload with interval');
            getOrderHandler({
                isRefresh: true,
                noLoading: true,
            });
        }, intervalAmount * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [restaurant]);

    const batchChangeHandler = (payload: IOrderItemStatus) => {
        setData((li) => {
            const cloneList = cloneDeep(li);
            const idx = cloneList.findIndex((o) => o.id === payload.id);
            if (idx > -1 && cloneList?.[idx]) {
                cloneList[idx].orderData.items = cloneList[idx].orderData?.items?.map((item) => {
                    if (item.uid === payload.uid) {
                        return {
                            ...item,
                            status: payload.status,
                        };
                    }
                    return item;
                });
            }
            return cloneList;
        });
    };

    const doneHandler = (status: OrderStatusEnum, ticketId: string, referenceId?: string) => {
        setData((li) => {
            const cloneList = cloneDeep(li);
            const idx = cloneList.findIndex((o) => o.refId === referenceId);
            if (idx > -1 && cloneList?.[idx]) {
                cloneList[idx].orderStatus = { name: status };
                cloneList[idx].ticketId = ticketId;
            }
            return cloneList;
        });
    };

    const printHandler = (id: string) => {
        const item = data.find((o) => o.id === id);
        if (!item) {
            return;
        }

        const payload: IQsrOrder[] = [
            {
                ...item,
                force: true,
                silent: true,
            },
        ];
        EventBus.dispatch(vendorOrderEvent, payload);
    };

    const editHandler = (id: string) => {
        if (!qsrEditOrderRef.current) {
            return;
        }

        const item = data.find((o) => o.id === id);
        if (!item) {
            return;
        }

        qsrEditOrderRef.current?.edit(item);
    };

    const noOrderComponent = () => {
        return (
            <Box
                sx={{
                    width: '100%',
                    paddingRight: '1.2em',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        paddingRight: '1.2em',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '20px',
                    }}
                >
                    {t('No Order Found!')}
                </Box>
                <KKTablePagination rowsPerPage={limit} rowCount={totalRow} currentPage={page} />
            </Box>
        );
    };

    if (hide) {
        return null;
    }

    if (view === 'table') {
        return (
            <Section>
                <DataTable
                    customStyles={{
                        table: {
                            style: {
                                fontFamily: 'Inter',
                            },
                        },
                        rows: {
                            style: {
                                borderBottom: 'none !important',
                            },
                        },
                    }}
                    striped
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationPerPage={limit}
                    paginationTotalRows={totalRow}
                    onChangeRowsPerPage={tableRowsPerPageChangeHandler}
                    onChangePage={tablePageChangeHandler}
                    paginationComponent={KKTablePagination}
                    noDataComponent={noOrderComponent()}
                />
                <QsrOrdersDetails
                    open={Boolean(selectedRow)}
                    data={selectedRow}
                    currencySymbol={restaurant?.restaurant_country?.currency_symbol || ''}
                    currencyCode={restaurant?.restaurant_country?.currency_code || ''}
                    restaurant={restaurant}
                    lang={lang}
                    onClose={() => {
                        setSelectedRow(null);
                    }}
                    onDone={doneHandler}
                    onEdit={editHandler}
                    onReloadOrder={onReloadOrder}
                />
            </Section>
        );
    }
    return (
        <Grid
            container
            spacing={1}
            sx={{
                padding: mode !== QSRCardModeEnum.Pending ? '0 0 54px' : undefined,
            }}
        >
            {loading && data.length > 0 && (
                <Grid item xs={12}>
                    <LinearProgress variant="indeterminate" />
                </Grid>
            )}
            <Grid item xs={12}>
                {!loading && data.length === 0 && (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                        }}
                    >
                        {t('There is no order with selected filters')}
                    </Box>
                )}
                {loading && (mode !== QSRCardModeEnum.Pending || data.length === 0) ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                        }}
                    >
                        {t('Loading...')}
                    </Box>
                ) : (
                    <QsrGridView
                        data={data}
                        currencySymbol={restaurant?.restaurant_country?.currency_symbol || ''}
                        currencyCode={restaurant?.restaurant_country?.currency_code || ''}
                        restaurant={restaurant}
                        onBatchChange={batchChangeHandler}
                        onDone={doneHandler}
                        onPrint={printHandler}
                        onEdit={editHandler}
                        onReloadOrder={onReloadOrder}
                        onLoadMore={
                            more && data.length > 0 && mode === QSRCardModeEnum.Pending
                                ? () => {
                                      setPage((p) => p + 1);
                                  }
                                : undefined
                        }
                        loading={loading}
                    />
                )}
            </Grid>
            {mode !== QSRCardModeEnum.Pending && (
                <Grid item xs={12}>
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: '#fff',
                            padding: '8px 16px',
                        }}
                    >
                        <KKTablePagination
                            rowsPerPage={limit}
                            rowCount={totalRow}
                            currentPage={page}
                            onChangeRowsPerPage={tableRowsPerPageChangeHandler}
                            onChangePage={tablePageChangeHandler}
                        />
                    </Box>
                </Grid>
            )}
        </Grid>
    );
}

function generateTimeRangeLabel(from: string | null, to: string | null) {
    if (!from || !to) return '';
    return `From ${new Date(from).getDate()} ${new Date(from).toLocaleDateString('en-US', {
        month: 'short',
    })} to ${new Date(to).getDate()} ${new Date(to).toLocaleDateString('en-US', { month: 'short' })}`;
}

export default function QsrOrders() {
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();
    const { restaurant, setRestaurant } = useRestaurantContext();
    const [prevFilter, setPrevFilter] = useLocalStorage('qsr_order_filter', {});
    const [tab, setTab] = useState<QSRCardModeEnum>(QSRCardModeEnum.Pending);
    const { pushToQuery } = useQueryParams();
    const router = useRouter();
    const qsrEditOrderRef = useRef<IQSREditOrderFunctions | null>(null);

    const { renderFilters, selectedStatus, timeRange, refId, refreshTrigger, view, config, removeFilter } = useFilters(
        tab,
        restaurant?.order_config?.vendorQsrCardViewPendingStatuses || [],
    );

    useEffect(() => {
        if (isEqual(config, {}) || !restaurant) {
            return;
        }
        if (isEqual(config, parsePrinterConfig(restaurant.order_config || {}))) {
            return;
        }

        RestaurantService.getInstance()
            .editRestaurantOrderConfig(restaurant.id || '', {
                orderConfig: transformPrinterConfig(config),
            })
            .then((res) => {
                setRestaurant(res);
            });
    }, [config]);

    const hasPendingSection = Boolean(restaurant?.order_config?.vendorQsrCardViewPendingStatuses?.length);
    const hasFailedSection = Boolean(restaurant?.order_config?.vendorQsrCardViewFailedSectionEnable);
    const hasScheduledSection = Boolean(restaurant?.order_config?.scheduledOrderEnable);

    useEffect(() => {
        setTab(
            !hasPendingSection && !hasFailedSection && !hasScheduledSection
                ? QSRCardModeEnum.Normal
                : QSRCardModeEnum.Pending,
        );
    }, [restaurant]);

    useEffect(() => {
        setPrevFilter({
            timeRange,
            selectedStatus,
            view,
        });
    }, [view, timeRange, selectedStatus]);

    const handleQueryChange = () => {
        pushToQuery({
            view: prevFilter.view,
            selectedStatus: prevFilter.selectedStatus?.join('-') || null,
            from: prevFilter.timeRange?.type === 'custom' ? prevFilter.timeRange?.from : null,
            to: prevFilter.timeRange?.type === 'custom' ? prevFilter.timeRange?.to : null,
            type: prevFilter.timeRange?.type,
        });
    };

    useEffect(() => {
        if (!router.isReady || Object.keys(prevFilter).length === 0 || !prevFilter) return;
        handleQueryChange();
    }, [restaurant, prevFilter]);

    useEffect(() => {
        if (Object.keys(router.query).length === 1) {
            handleQueryChange();
        }
    }, [router.query]);

    const reloadOrderHandler = useCallback(
        (orderRefId: string) => {
            if (!restaurant?.id) {
                return Promise.resolve(new Error('no restaurant'));
            }

            return QsrOrdersService.getInstance()
                .getQsrOrderByRefId(restaurant.id, orderRefId)
                .then((data) => {
                    if (!data) {
                        return;
                    }

                    const payload: IQsrOrder[] = [
                        {
                            ...data.order,
                            silent: true,
                            skipPrint: true,
                        },
                    ];
                    EventBus.dispatch(vendorOrderEvent, payload);
                })
                .catch((err) => {
                    enqueueSnackbar(err?.message || err, { variant: 'error' });
                });
        },
        [restaurant],
    );

    if (!restaurant) {
        return null;
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <PageTitle
                        button={renderFilters()}
                        sx={{
                            paddingBottom: 0,
                        }}
                    />
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                            overflowX: 'scroll',
                            height: '50px',
                        }}
                    >
                        <Chip
                            {...(timeRange.type !== 'today'
                                ? {
                                      onDelete: () => removeFilter('timeRange'),
                                  }
                                : {})}
                            label={
                                <span>
                                    <b>Filter by:</b>{' '}
                                    {timeRange.type === 'custom'
                                        ? generateTimeRangeLabel(timeRange.from, timeRange.to)
                                        : timeRangeTitles(t)[timeRange.type]}
                                </span>
                            }
                            sx={{
                                borderRadius: '8px',
                                margin: '5px',
                                padding: '10px',
                                color: '#1A1C23',
                                background: '#EBECF2',
                                borderColor: '#EBECF2',
                                '& .MuiChip-deleteIcon': {
                                    color: '#7D00D4',
                                    fontSize: '20px',
                                },
                            }}
                        />
                        {selectedStatus.length > 0 && (
                            <Chip
                                onDelete={() => {
                                    removeFilter('status');
                                }}
                                label={
                                    <span>
                                        <b>Status by:</b>{' '}
                                        {`${selectedStatus
                                            .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
                                            .join(', ')} status`}
                                    </span>
                                }
                                sx={{
                                    borderRadius: '8px',
                                    margin: '5px',
                                    padding: '10px',
                                    color: '#1A1C23',
                                    background: '#EBECF2',
                                    borderColor: '#EBECF2',
                                    '& .MuiChip-deleteIcon': {
                                        color: '#7D00D4',
                                        fontSize: '20px',
                                    },
                                }}
                            />
                        )}
                        <Chip
                            icon={getIcon({ value: view }, true)}
                            label={`${view.charAt(0).toUpperCase()}${view.slice(1)} view`}
                            sx={{
                                borderRadius: '8px',
                                margin: '5px',
                                padding: '10px',
                                color: '#1A1C23',
                                background: '#EBECF2',
                                borderColor: '#EBECF2',
                            }}
                        />
                    </Box>
                </Grid>
                {(hasPendingSection || hasFailedSection || hasScheduledSection) && (
                    <Grid item xs={12}>
                        <Tabs
                            variant="fullWidth"
                            value={tab}
                            onChange={(e, val) => {
                                setTab(val as QSRCardModeEnum);
                            }}
                        >
                            {hasFailedSection && (
                                <Tab value={QSRCardModeEnum.Failed} label={t('Failed Orders')} color="error" />
                            )}
                            <Tab value={QSRCardModeEnum.Pending} label={t('Pending Orders')} />
                            {hasScheduledSection && <Tab value={QSRCardModeEnum.Scheduled} label={t('Scheduled')} />}
                            <Tab value={QSRCardModeEnum.NotPending} label={t('All Other Orders')} />
                        </Tabs>
                    </Grid>
                )}
                {hasFailedSection && (
                    <Grid item xs={12} sx={{ display: tab === QSRCardModeEnum.Failed ? 'block' : 'none' }}>
                        <QsrOrdersInner
                            hide={tab !== QSRCardModeEnum.Failed}
                            mode={QSRCardModeEnum.Failed}
                            restaurant={restaurant}
                            view="table"
                            timeRange={timeRange}
                            selectedStatus={[OrderStatusEnum.Failed]}
                            refId={refId}
                            refreshTrigger={refreshTrigger}
                            qsrEditOrderRef={qsrEditOrderRef}
                            onReloadOrder={reloadOrderHandler}
                        />
                    </Grid>
                )}
                {(hasPendingSection || hasScheduledSection) && (
                    <Grid item xs={12} sx={{ display: tab === QSRCardModeEnum.Pending ? 'block' : 'none' }}>
                        <QsrOrdersInner
                            hide={tab !== QSRCardModeEnum.Pending}
                            mode={QSRCardModeEnum.Pending}
                            restaurant={restaurant}
                            view={view}
                            timeRange={timeRange}
                            selectedStatus={[
                                ...(restaurant.order_config?.vendorQsrCardViewPendingStatuses || []),
                                ...(hasScheduledSection ? [OrderStatusEnum.Pending] : []),
                            ]}
                            refId={refId}
                            refreshTrigger={refreshTrigger}
                            qsrEditOrderRef={qsrEditOrderRef}
                            onReloadOrder={reloadOrderHandler}
                        />
                    </Grid>
                )}
                {hasScheduledSection && (
                    <Grid item xs={12} sx={{ display: tab === QSRCardModeEnum.Scheduled ? 'block' : 'none' }}>
                        <QsrOrdersInner
                            hide={tab !== QSRCardModeEnum.Scheduled}
                            mode={QSRCardModeEnum.Scheduled}
                            restaurant={restaurant}
                            view={view}
                            timeRange={timeRange}
                            selectedStatus={[OrderStatusEnum.Scheduled]}
                            refId={refId}
                            refreshTrigger={refreshTrigger}
                            qsrEditOrderRef={qsrEditOrderRef}
                            onReloadOrder={reloadOrderHandler}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display:
                            tab === QSRCardModeEnum.NotPending ||
                            (!hasPendingSection && !hasFailedSection && !hasScheduledSection)
                                ? 'block'
                                : 'none',
                    }}
                >
                    <QsrOrdersInner
                        hide={
                            !(
                                tab === QSRCardModeEnum.NotPending ||
                                (!hasPendingSection && !hasFailedSection && !hasScheduledSection)
                            )
                        }
                        mode={hasPendingSection ? QSRCardModeEnum.NotPending : QSRCardModeEnum.Normal}
                        restaurant={restaurant}
                        view={view}
                        timeRange={timeRange}
                        selectedStatus={selectedStatus}
                        notInSelectedStatus={[
                            ...(restaurant.order_config?.vendorQsrCardViewPendingStatuses || []),
                            ...(hasScheduledSection ? [OrderStatusEnum.Scheduled, OrderStatusEnum.Pending] : []),
                        ]}
                        refId={refId}
                        refreshTrigger={refreshTrigger}
                        qsrEditOrderRef={qsrEditOrderRef}
                        onReloadOrder={reloadOrderHandler}
                    />
                </Grid>
            </Grid>
            {restaurant?.order_config?.vendorEditOrderEnable && (
                <QSREditOrder ref={qsrEditOrderRef} restaurantId={restaurant.id} onReloadOrder={reloadOrderHandler} />
            )}
        </>
    );
}
