import React, {
    useState,
    useCallback,
    useImperativeHandle,
    forwardRef,
    useRef,
    useEffect,
    MutableRefObject,
} from 'react';
import { Drawer } from '@mui/material';
import IframeBridge from '@/services/iframeBridge';
import MenuService from '@/services/menu/menuService';
import { IQsrOrder } from '@/views/QsrOrders/types';
import Loading from '@/components/Loading';
import { customerAppUrl } from '@/config/axios';
import { IURLTopology } from '@/services/iframeBridge/type';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-export-i18n';
import { timeUnix } from '@/services/utils/k_time';

import styles from './index.module.scss';

const getIframeUrl = (order: IQsrOrder) => {
    return `${customerAppUrl}/qr/${order.urlData.cc}/${order.urlData.slug}/${order.urlData.tableId}/${order.urlData.f1}/${order.urlData.f2}/${order.urlData.hash}/embed/qsr/order_details?theme=black&lang=en&qsr=true&embed=vendor`;
};

const getUrl = (order: IQsrOrder): IURLTopology => {
    return {
        cc: order.urlData.cc,
        slug: order.urlData.slug,
        id: order.urlData.tableId,
        f1: order.urlData.f1,
        f2: order.urlData.f2,
        hash: order.urlData.hash || '_',
    };
};

export type QSREditOrderType = MutableRefObject<IQSREditOrderFunctions | null>;

export interface IQSREditOrderFunctions {
    edit: (order: IQsrOrder) => void;
}

interface IProps {
    restaurantId: string;
    onReloadOrder: (refId: string) => Promise<any>;
}

const QSREditOrder = forwardRef<IQSREditOrderFunctions, IProps>(({ restaurantId, onReloadOrder }, ref) => {
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const [lastTokenUnix, setLastTokenUnix] = useState(0);
    const [url, setUrl] = useState('');
    const [order, setOrder] = useState<IQsrOrder | null>(null);
    const iframeService = useRef<IframeBridge | null>(null);

    const closeHandler = useCallback(() => {
        setOpen(false);
        setOrder(null);
    }, []);

    const loadOrder = useCallback(
        ({ refId, token: _token, order: _order }: { refId: string; token: string; order: IQsrOrder }) => {
            console.log('send loadOrder');
            setTimeout(() => {
                iframeService.current
                    ?.loadOrder({ refId, token: _token, url: getUrl(_order) })
                    .then(() => {
                        setLoading(false);
                    })
                    .catch((err) => {
                        enqueueSnackbar(err.err || err.message || err, { variant: 'error' });
                    });
            }, 100);
        },
        [],
    );

    const editHandler = useCallback(
        (val: IQsrOrder) => {
            setOpen(true);
            setLoading(true);
            if (!url) {
                setUrl(getIframeUrl(val));
            }
            const unix = timeUnix();
            const validTime = unix - lastTokenUnix < 60 * 60;
            if (!validTime) {
                setToken('');
                setTimeout(() => {
                    setOrder(val);
                }, 100);
            } else {
                setOrder(val);
            }
            if (loaded && token && url && validTime) {
                loadOrder({ refId: val.refId, token, order: val });
            }
        },
        [token, loaded, url, lastTokenUnix, loadOrder],
    );

    useEffect(() => {
        if (!token || !loaded || !order) {
            return;
        }

        loadOrder({ refId: order.refId, token, order });
    }, [loaded]);

    useImperativeHandle(
        ref,
        () => {
            return {
                edit: editHandler,
            };
        },
        [editHandler],
    );

    useEffect(() => {
        if (!restaurantId || token) {
            return;
        }

        MenuService.getInstance()
            .getVendorTokenAccess(restaurantId)
            .then((res) => {
                setToken(res.token);
                setLastTokenUnix(timeUnix());
                if (order) {
                    loadOrder({ refId: order.refId, token: res.token, order });
                }
            })
            .catch((err) => {
                enqueueSnackbar(t('cannot generate token!'), { variant: 'error' });
            });
    }, [restaurantId, token, loadOrder, order]);

    const iframeRefHandler = useCallback((iframe: HTMLIFrameElement) => {
        if (!iframe) {
            return () => {
                //
            };
        }

        iframeService.current = IframeBridge.getInstance(iframe);
        iframeService.current.onLoad(() => {
            console.log('vendor | customer app loaded');
            setLoaded(true);
        });

        const canceller = iframeService.current?.api('/order/submit', (res) => {
            onReloadOrder(res.data?.order?.refId || '').finally(() => {
                closeHandler();
            });
            return Promise.resolve({
                data: {
                    success: true,
                },
            });
        });

        return () => {
            canceller?.();
        };
    }, []);

    const loadHandler = useCallback(() => {
        console.log('loaded');
    }, []);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={closeHandler}
            keepMounted
            PaperProps={{
                sx: {
                    overflowY: 'hidden',
                },
            }}
        >
            <div className={styles.content}>
                {url && <iframe ref={iframeRefHandler} onLoad={loadHandler} title="Edit Order" src={url} />}
                {loading && (
                    <div className={styles.loading}>
                        <Loading />
                    </div>
                )}
            </div>
        </Drawer>
    );
});

export default QSREditOrder;
