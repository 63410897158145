// eslint-disable-next-line import/no-duplicates
import { tr, enAU, pt, ja, ar, zhCN, es } from 'date-fns/locale';
import {
    endOfDay,
    endOfMonth,
    startOfDay,
    startOfMonth,
    parseISO,
    isBefore,
    formatISO as formatDateISo,
    parse,
    isValid,
    // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
import { type ITimeRangeForm, TimeRangeTypes } from './types';

export const getLocaleLang = (lang: string) => {
    switch (lang) {
        case 'tr':
            return tr;
        case 'pt':
            return pt;
        case 'ja':
            return ja;
        case 'ar':
            return ar;
        case 'zh':
            return zhCN;
        case 'es':
            return es;
        default:
            return enAU;
    }
};

export const formatISO = (date: Date) => {
    return date.toISOString();
};

export const parseDate = (date: string) => {
    if (date.toLowerCase().includes('invalid')) {
        return new Date();
    }
    return new Date(date);
};

export const formatISOTimezone = (dateISO: string) => {
    const date = parseISO(dateISO);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

export const getDataRangeFromFilter = (
    dateRange: ITimeRangeForm,
): { from: string | null; to: string | null } | null => {
    const date = new Date();
    switch (dateRange.type) {
        case TimeRangeTypes.CUSTOM:
            return {
                from: dateRange.from,
                to: dateRange.to,
            };
        case TimeRangeTypes.TODAY:
            return {
                from: formatISO(startOfDay(date)),
                to: formatISO(endOfDay(date)),
            };
        case TimeRangeTypes.MONTH:
            return {
                from: formatISO(startOfMonth(date)),
                to: formatISO(endOfMonth(date)),
            };
        default:
            return null;
    }
};

const isISOFormat = (date: string) => {
    const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:[+-]\d{2}:\d{2}|Z)?$/;
    return isoRegex.test(date);
};

const normalizeISODate = (date: string) => {
    return date.replace(' ', '+');
};

export const formatDateToLocalISO = (date: string, isEndDay: boolean, lang: string) => {
    const normalizedDate = normalizeISODate(date);
    const startToday = startOfDay(new Date());
    const endToday = endOfDay(new Date());

    if (isISOFormat(normalizedDate)) {
        const inputDate = parseISO(normalizedDate);

        if (isEndDay) {
            const adjustedDate = isBefore(startToday, inputDate) ? startToday : inputDate;
            return formatDateISo(endOfDay(adjustedDate), { format: 'extended', representation: 'complete' });
        }
        if (isBefore(inputDate, startToday)) {
            return formatDateISo(inputDate, { format: 'extended', representation: 'complete' });
        }
        return formatDateISo(startToday, { format: 'extended', representation: 'complete' });
    }

    const inputDate = parse(date, 'dd-MM-yyyy', new Date(), {
        locale: getLocaleLang(lang),
    });

    if (!isValid(inputDate)) {
        return formatDateISo(startToday, { format: 'extended', representation: 'complete' });
    }

    if (isEndDay && isBefore(endToday, inputDate)) {
        return formatDateISo(endToday, { format: 'extended', representation: 'complete' });
    }

    const finalDate = isEndDay ? endOfDay(inputDate) : inputDate;

    if (!isValid(finalDate)) {
        return formatDateISo(startToday, { format: 'extended', representation: 'complete' });
    }

    return formatDateISo(finalDate, { format: 'extended', representation: 'complete' });
};

export const timeRangeChangeHandler = (type: TimeRangeTypes) => {
    const val = type;
    const date = new Date();
    let from: Date | undefined = new Date();
    let to: Date | undefined = new Date();

    switch (val) {
        case 'today':
            from = startOfDay(date);
            to = endOfDay(date);
            break;
        case 'all':
            from = undefined;
            to = undefined;
            break;
        case 'month':
            from = startOfMonth(date);
            to = endOfMonth(date);
            break;
        default:
            break;
    }
    return {
        from: from ? formatDateISo(from) : null,
        to: to ? formatDateISo(to) : null,
    };
};
